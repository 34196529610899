.hero-gradient {
  background-image: linear-gradient(
    to top,
    var(--chakra-colors-teal-500),
    var(--chakra-colors-blue-400)
  );
  position: relative;
}

html {
  scroll-behavior: smooth;
  transition: all 4s ease;
}
